import { decode } from "../../../../base/helpers/html-helper.js";

export default class NewsItemLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleBackButton();
        columnize();
        registerPager();
    }

    render() {
        const item = this.props;
        if (!item) {
            return null;
        }

        let itemBody = "";
        if (item.body) {
            const regex = /<a href="(.*?)">(.*?)<\/a>/gi;
            itemBody = item.body.replaceAll(regex, "\\2");
            itemBody = decode(itemBody);
        }

        let files = [];
        if (item.files) {
            item.files.map((file, idx) => {
                if (!file.showable) {
                    return;
                }

                files.push(
                    <a
                        href={file.url}
                        className="file-item popup-image iframe"
                        key={idx}
                    >
                        <span className="icon"></span>
                        <span className="caption">{file.caption}</span>
                    </a>
                );
            });
        }

        let images = [];
        if (item.images) {
            item.images.map((image, idx) => {
                images.push(
                    <a
                        href={image.url}
                        className="file-item popup-image"
                        key={idx}
                    >
                        <span className="icon"></span>
                        <span className="caption">{image.caption}</span>
                    </a>
                );
            });
        }

        return (
            <div>
                <div className="page-caption">
                    <h1>{item.caption}</h1>
                </div>
                <p className="card-date">{item.date}</p>

                <div className="card-container column-container">
                    <div className="columns-inner">
                        <div
                            className="body"
                            dangerouslySetInnerHTML={{ __html: itemBody }}
                        />

                        <div className="card-files">
                            <div className="container">
                                {files}
                                {images}
                            </div>
                        </div>

                        {/*<div className="back-btn-space">*/}
                        {/*    <a className="back-btn" href="<?= $page->backBtn->url ?>">*/}
                        {/*<span className="text">*/}
                        {/*    <span className="icon"></span><?= $page->backBtn->caption ?>*/}
                        {/*</span>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="pager js-pager">
                    <ul>
                        <li className="previous hidden">
                            <a href="#"></a>
                        </li>
                        <li className="page">
                            <a href="#">1</a>
                        </li>
                        <li className="next hidden">
                            <a href="#"></a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    handleBackButton() {
        const backButton = document.querySelector(".back-btn-space");
        const container = document.querySelector(
            ".card-container .columns-inner"
        );

        if (backButton && container) {
            backButton.setAttribute("style", "display:none");
            const newBackButton = backButton.cloneNode(true);
            newBackButton.setAttribute("style", null);
            container.insertAdjacentElement("beforeend", newBackButton);
        }
    }
}
