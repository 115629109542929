import { OAuth } from "./oauth.js";
import * as RequestHelper from "../request-helper.js";

export class ConstTokenAuth {
    constructor(config) {
        this.token = config.token;
    }

    getToken(params) {
        return Promise.resolve({
            access_token: this.token,
            expires_in: 24 * 69 * 60,
        });
    }

    refreshToken(params) {
        return Promise.resolve({
            access_token: this.token,
            expires_in: 24 * 69 * 60,
        });
    }
}
