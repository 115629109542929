import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let hostCaption = this.props.host_caption;
        if (hostCaption) {
            hostCaption = <span>{hostCaption}</span>;
        }

        let annotation;
        if (this.props.annotation) {
            annotation = (
                <div className="text-container">
                    <span className="news-text">{this.props.annotation}</span>
                </div>
            );
        }

        let img;
        if (this.props.image) {
            img = (
                <div className="image-prev">
                    <div className="img-container">
                        <img
                            src={this.props.image}
                            alt=""
                            className="img-prev"
                        />
                    </div>
                </div>
            );
        } else {
            img = (
                <div className="image-prev">
                    <div className="no-image"></div>
                </div>
            );
        }

        return (
            <div className="news-item">
                <div className="news-caption">
                    <span>{this.props.date}</span>
                    <h3 className="news-header">{this.props.caption}</h3>
                    <ActionPanel {...this.props.cms} />
                    {hostCaption}
                </div>
                <div className="news-preview">
                    {img}
                    {annotation}
                </div>
                <a href={this.props.url}>Подробнее</a>
            </div>
        );
    }
}
