import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="news-item">
                <div className="news-caption">
                    <h3 className="news-header">
                        {this.props.caption}
                        <ActionPanel {...this.props.cms} />
                    </h3>
                    <p className="news-date">{this.props.date}</p>
                </div>
                <div className="news-preview">
                    <div className="text-container">
                        <div className="news-text">{this.props.annotation}</div>
                    </div>
                    <a href={this.props.url}></a>
                </div>
            </div>
        );
    }
}
