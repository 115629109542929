import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let hostCaption = this.props.host_caption;
        if (hostCaption) {
            hostCaption = <p className="date">{hostCaption}</p>;
        }

        return (
            <div className="list-item">
                <div class="date">
                    <p>{this.props.date}</p>
                </div>
                <div className="caption">
                    <a href={this.props.url}>{this.props.caption}</a>
                    <ActionPanel {...this.props.cms} />
                </div>
                <div class="annotation">
                    <p>{this.props.annotation}</p>
                </div>
                <div className="counter">
                    <div className="counter"></div>
                </div>
            </div>
        );
    }
}
