import { Auth } from "./auth/auth.js";
import { ConstTokenAuth } from "./auth/handlers/const-token-auth.js";
import { Cryptor } from "./auth/cryptor.js";

let auth = new Auth();

let config = {
    debug: false,
};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }

    auth = new Auth({
        handler: new ConstTokenAuth(config),
        cryptor: new Cryptor(),
        // tokenParser: (response) => {
        //     if (!response || !response.token) {
        //         return null;
        //     }
        //     return {
        //         access_token: response.token.accessToken,
        //         expires_in: response.token.lifetime,
        //         refresh_token: response.token.refreshToken,
        //     };
        // },
        on: {
            debugMessage: (message) => {
                if (config.debug) {
                    console.log("AUTH: " + message);
                }
            },
        },
    });

    auth.unsetToken();
    // т.к. у нас токен статичный - сразу установим его
    // auth.handler.getToken().then((response) => {
    //     auth.setToken(response);
    // });

    if (config.debug) {
        window.auth = auth;
    }
}

export function authorize(params) {
    return auth.login(params);
}

export function logout() {
    return auth.logout();
}

export function checkAuthorization() {
    return auth.checkAuthorization();
}

export function request(method, url, body, options) {
    options = options || {};
    options.method = method;
    options.body = body;
    return auth.request(url, options);
}
