// https://stackoverflow.com/questions/29420835/how-to-generate-unique-ids-for-form-labels-in-react

let lastId = 0;

const newId =function(prefix='id') {
    lastId++;
    return `${prefix}${lastId}`;
}

export default newId;
