import ListItem from "./list-item.jsx";
import Paginator from "../../pager.jsx";

export default class NewsListLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let list = [];

        if (this.props.list) {
            this.props.list.map(function (item, key) {
                list.push(<ListItem key={key} {...item} />);
            });
        }

        return (
            <div className="ns-news-list-layout">
                <div className="pub-list news">
                    {list}
                    <div className="clear"></div>
                </div>
                {this.renderPager()}
            </div>
        );
    }

    renderPager() {
        const { totalCount, list } = this.props;
        if (!list || !list.length || !totalCount) {
            return null;
        }

        if (totalCount <= list.length) {
            return null;
        }

        const links = [];
        const listLimit = this.props.listLimit || list.length;
        const pagesCount = Math.ceil(totalCount / listLimit);
        const activePage = this.props.currentPage || 1;

        for (let page = 1; page <= pagesCount; page++) {
            links.push({
                page: page,
            });
        }

        return (
            <div className="pager">
                <Paginator
                    links={links}
                    active={activePage}
                    onPageClick={this.props.handlePageClick || function () {}}
                />
            </div>
        );
    }
}
