import BaseNewsSideListLayout from "../../../../base/components/layouts/news-side-list/index.jsx";
import V3NewsSideListLayout from "../../../../v3/components/layouts/news-side-list/index.jsx";
import V4NewsSideListLayout from "../../../../v4/components/layouts/news-side-list/index.jsx";
import V5NewsSideListLayout from "../../../../v5/components/layouts/news-side-list/index.jsx";
import V6NewsSideListLayout from "../../../../v6/components/layouts/news-side-list/index.jsx";

import ThemeContainer from "../../../theme-container.jsx";

export default class NewsSideListLayoutThemeContainer extends ThemeContainer {
    constructor(props) {
        super(props);
    }

    render() {
        switch (this.theme.name) {
            case "v3":
                return <V3NewsSideListLayout {...this.props} />;
            case "v4":
                return <V4NewsSideListLayout {...this.props} />;
            case "v5":
                return <V5NewsSideListLayout {...this.props} />;
            case "v6":
                return <V6NewsSideListLayout {...this.props} />;
            default:
                return <BaseNewsSideListLayout {...this.props} />;
        }
    }
}
