import BaseNewsItemLayout from "../../../../base/components/layouts/news-item/index.jsx";
import V2NewsItemLayout from "../../../../v2/components/layouts/news-item/index.jsx";
import V3NewsItemLayout from "../../../../v3/components/layouts/news-item/index.jsx";
import V4NewsItemLayout from "../../../../v4/components/layouts/news-item/index.jsx";
import V5NewsItemLayout from "../../../../v5/components/layouts/news-item/index.jsx";
import V6NewsItemLayout from "../../../../v6/components/layouts/news-item/index.jsx";
import Gov2NewsItemLayout from "../../../../gov2/components/layouts/news-item/index.jsx";
import KioskNewsItemLayout from "../../../../kiosk/components/layouts/news-item/index.jsx";
import UoBaseNewsItemLayout from "../../../../uo-base/components/layouts/news-item/index.jsx";

import ThemeContainer from "../../../theme-container.jsx";

export default class NewsItemLayoutThemeContainer extends ThemeContainer {
    constructor(props) {
        super(props);
    }

    render() {
        switch (this.theme.name) {
            case "v2":
                return <V2NewsItemLayout {...this.props} />;
            case "v3":
                return <V3NewsItemLayout {...this.props} />;
            case "v4":
                return <V4NewsItemLayout {...this.props} />;
            case "v5":
                return <V5NewsItemLayout {...this.props} />;
            case "v6":
                return <V6NewsItemLayout {...this.props} />;
            case "gov2":
                return <Gov2NewsItemLayout {...this.props} />;
            case "kiosk":
                return <KioskNewsItemLayout {...this.props} />;
            case "uo-base":
                return <UoBaseNewsItemLayout {...this.props} />;
            default:
                return <BaseNewsItemLayout {...this.props} />;
        }
    }
}
