import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let hostCaption = this.props.host_caption;
        if (hostCaption) {
            hostCaption = <span>{hostCaption}</span>;
        }

        let annotation;
        if (this.props.annotation) {
            annotation = (
                <div className="text-container">
                    <div className="news-text box-bottom-shadow">
                        {this.props.annotation}
                    </div>
                </div>
            );
        }

        return (
            <div className="news-item">
                <div className="news-caption">
                    <span>{this.props.date}</span>
                    <h3 className="news-header">{this.props.caption}</h3>
                    <ActionPanel {...this.props.cms} />
                    {hostCaption}
                </div>
                <div className="news-preview">
                    {annotation}
                    <a href={this.props.url} className="button borders"></a>
                </div>
            </div>
        );
    }
}
