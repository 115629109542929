import { decode } from "../../../../base/helpers/html-helper.js";
import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class NewsItemLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const item = this.props;
        if (!item) {
            return null;
        }

        let dates = [];
        if (item.dates) {
            item.dates.map((date, i) => {
                dates.push(<p key={i}>{date}</p>);
            });
        }

        let images = [],
            popupBigImages = [],
            popupPreviewImages = [];
        if (item.images) {
            item.images.map((image, i) => {
                images.push(
                    <div className="image-item tiles-link-item">
                        <button
                            className="img popup-gallery-btn"
                            data-gallery-image-id={i}
                            key={i}
                        >
                            <img src={image.urls.thumb} alt="pic" />
                            <span className="hover-veil"></span>
                        </button>
                    </div>
                );
                popupBigImages.push(
                    <div className="img" data-gallery-image-id={i} key={i}>
                        <img data-src={image.urls.big} alt="pic" />
                    </div>
                );
                popupPreviewImages.push(
                    <div className="img" data-gallery-image-id={i} key={i}>
                        <img data-src={image.urls.thumb} alt="pic" />
                        <div className="hover-veil"></div>
                    </div>
                );
            });

            images = [
                <div className="images-container" key={1}>
                    <div className="images-gallery tiles-links row-3">
                        {images}
                    </div>
                </div>,
                <div className="popup-images-gallery" key={2}>
                    <div className="root-container">
                        <div className="center-container">
                            <div className="big-images">{popupBigImages}</div>
                        </div>
                        <div className="bottom-container">
                            <div className="previews">{popupPreviewImages}</div>
                            <button className="next">
                                <span className="icon"></span>
                            </button>
                            <button className="prev">
                                <span className="icon"></span>
                            </button>
                        </div>
                    </div>
                    <button className="close">&#215;</button>
                </div>,
            ];
        }

        let files = [],
            links = [];
        if (item.files) {
            item.files.map((file, i) => {
                links = [];
                if (file.showUrl) {
                    links.push(
                        <a
                            href={file.showUrl}
                            className="link"
                            target="_blank"
                            key="show-url"
                        >
                            Посмотреть
                        </a>
                    );
                    links.push(<span key="delim-show-url"> </span>);
                }
                if (file.downloadUrl) {
                    links.push(
                        <a
                            href={file.downloadUrl}
                            className="link"
                            target="_blank"
                            key="download-url"
                        >
                            Скачать
                        </a>
                    );
                    links.push(<span key="delim-download-url"> </span>);
                }
                if (!links.length && file.url) {
                    links.push(
                        <a
                            href={file.url}
                            className="link"
                            target="_blank"
                            key="url"
                        >
                            Скачать
                        </a>
                    );
                    links.push(<span key="delim-url"> </span>);
                }
                files.push(
                    <div
                        className="tiles-link-item file dark neutral-hover"
                        key={i}
                    >
                        <div className="content">
                            <div className="left">
                                <div className="icon-image">
                                    <div
                                        className={"image image-" + file.ext}
                                    ></div>
                                </div>
                                <div className="body">
                                    <div className="top">
                                        <div className="caption">
                                            {file.caption}
                                        </div>
                                    </div>
                                    <div className="bottom">{links}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

            files = (
                <div className="files-gallery tiles-links row-2">{files}</div>
            );
        }

        return (
            <div className="pub-item">
                <h1 className="caption">
                    {item.caption}
                    <ActionPanel {...this.props.cms} />
                </h1>
                <div className="pub-body">
                    <div className="date">
                        <p className="date-custom">{item.date}</p>
                    </div>
                    <div
                        className="body"
                        dangerouslySetInnerHTML={{ __html: decode(item.body) }}
                    />
                    {images}
                    {files}
                </div>
                <div className="dates-list">{dates}</div>
            </div>
        );
    }
}
