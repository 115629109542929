import * as NewsListHandler from "../handlers/news-list-handlers.js";

export function handleLoadMoreButtonClick(button) {
    return NewsListHandler.loadMoreList({
        url: button.url,
    });
}

export function handlePageClick(page) {
    return NewsListHandler.loadPageList(page);
}
