import Container from "../container.jsx";
import LoadingPopup from "../../components/loading-popup.jsx";

export default class LoadingPopupContainer extends Container {
    constructor(props) {
        super(props);
        this.connectStore("loading");
    }

    render() {
        return <LoadingPopup {...this.state.state} />;
    }
}
