import ListItem from "./list-item.jsx";

export default class NewsListLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        kioskIndexBlockBanner();
    }

    render() {
        let list = [];

        if (this.props.list) {
            this.props.list.map(function (item, key) {
                list.push(<ListItem key={key} {...item} pagerKey={key} />);
            });
        }

        return (
            <div className="ns-news-list-layout">
                <div className="blocks-banner">{list}</div>
                {this.renderPager()}
            </div>
        );
    }

    renderPager() {
        let links = [];
        let counter = 1;
        if (this.props.list) {
            this.props.list.map(function (item, key) {
                links.push(
                    <a href={"#kiosk-banner-item-" + key} key={key}>
                        {counter++}
                    </a>
                );
            });
        }
        return <div className="banner-navi">{links}</div>;
    }
}
