import * as Auth from "../auth.js";

export let config = {
    baseUrl: "",
    queryUrl: "/query",
    authLayoutUrl: "/auth",
};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function request(method, url, body, options) {
    if (url.indexOf(config.baseUrl) <= -1) {
        url = config.baseUrl + url;
    }
    return Auth.request(method, url, body, options);
}

export function queryRequest(query) {
    return request("post", config.baseUrl + config.queryUrl, {
        query: JSON.stringify(query),
    });
}
