export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let img;
        if (this.props.image) {
            img = (
                <div className="img">
                    <img src={this.props.image} alt="" />
                </div>
            );
        } else {
            img = <div className="img"></div>;
        }

        return (
            <a
                className="blocks-item"
                href={this.props.url}
                data-id={"kiosk-banner-item-" + this.props.pagerKey}
            >
                {img}
                <span
                    className={"content " + (this.props.image ? "imged" : "")}
                >
                    <span className="container">
                        <span className="meta">
                            <span className="date">{this.props.date}</span>
                        </span>
                        <span className="caption">
                            <span>{this.props.caption}</span>
                        </span>
                    </span>
                </span>
            </a>
        );
    }
}
