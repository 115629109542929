import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class ListItemBig extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let img = (
            <span className="default-image">
                <img
                    src="/css/images/site/default-pub-icon.png"
                    alt="Публикация"
                />
            </span>
        );

        if (this.props.image) {
            img = <img src={this.props.image} alt="" />;
        }

        return (
            <div className="big-pubs-item">
                <div className="pubs-image">
                    <a href={this.props.url}>{img}</a>
                </div>
                <div className="pubs-body">
                    <div className="part-top">
                        <p className="caption">
                            <a href={this.props.url}>{this.props.caption}</a>
                        </p>
                        <ActionPanel {...this.props.cms} />
                        <div className="annotation">
                            {this.props.annotation}
                        </div>
                    </div>
                    <div className="part-bottom">
                        <div className="date">
                            <p className="date-custom">{this.props.date}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
