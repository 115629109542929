import * as Store from "../../store.js";
import * as Router from "../../router.js";
import * as AuthHandlers from "../handlers/auth-handlers.js";
import * as NewsListHandlers from "../handlers/news-list-handlers.js";
import * as NewsItemHandlers from "../handlers/news-item-handlers.js";

export function loadLayout(name, params, sections) {
    if (!name) {
        return Promise.reject();
    }
    return loadLayoutByName(name, params, sections).then((response) => {
        setLayout(name);
        setLayoutHistoryState(name, params);
        return response;
    });
}

export function loadHomeLayout() {
    const homeLayout = Store.getStore("config.homeLayout");
    if (!homeLayout) {
        return Promise.reject("undefined home layout");
    }

    return loadLayout(homeLayout.name, homeLayout.params);
}

export function loadErrorLayout(error) {
    debugMessage(error);
    Store.setStore(null, {
        layout: "error",
    });
}

export function loadLayoutByName(name, params, sections) {
    const handler = getHandler(name);
    if (!handler) {
        return Promise.resolve();
    }

    return handler.loadLayout(params, sections);
}

export function handleRequestFail(error) {
    if (error && error.message.indexOf("auth.") == 0) {
        // запрос неавторизован - перезагрузим страницу просто
        location.href = location.href;
    } else {
        // что-то другое
        // loadErrorLayout();
        // или мжб всплываху...
        Store.setStore("data.popupErrors", {
            list: ["Ошибка загрузки, обратитесь в техподдержку"],
        });
    }
}

export function handlePopupErrors(response) {
    if (response && response.errors) {
        Store.setStore("data.popupErrors", { list: response.errors });
    }
}

export function handlePopupMessages(response) {
    if (response && response.messages) {
        Store.setStore("data.popupMessages", { list: response.messages });
    }
}

export function closeErrorsPopup() {
    Store.setStore("data.popupErrors", null);
}

export function closeMessagesPopup() {
    Store.setStore("data.popupMessages", null);
}

export function setLayoutHistoryState(name, params) {
    if (!Store.getStore("config.historyState")) {
        return true;
    }
    if (setHandlerLayoutHistoryState(name, params)) {
        return true;
    }

    let title = Store.config.appName || "Application";
    document.title = title;
    let path = "/";

    if (name) {
        path += name;
    }

    const idParam = Router.getLayoutIdParam(name);
    if (params && params[idParam]) {
        path += "/" + params[idParam];
    }
    history.pushState({}, title, path);
    return true;
}

export function setDialogHistoryState() {
    let currentUrl = location.href.split("#")[0];
    history.pushState({}, document.title, currentUrl + "#dialog");
}

export function unsetDialogHistoryState() {
    let currentUrl = location.href.split("#")[0];
    history.pushState({}, document.title, currentUrl);
}

/* HELPERS */

function debugMessage(message, data) {
    if (Store.config.debug) {
        console.log(message, data);
    }
}

function setLayout(name) {
    Store.setStore("layout", name);
}

function getHandler(name) {
    switch (name) {
        case "auth":
            return AuthHandlers;
        case "news-list":
        case 'news-index-list':
        case 'news-side-list':
            return NewsListHandlers;
        case "news-item":
            return NewsItemHandlers;
    }
}

function setHandlerLayoutHistoryState(name, params) {
    const handler = getHandler(name);
    if (!handler) {
        return null;
    }
    if (typeof handler.setLayoutHistoryState == "function") {
        return handler.setLayoutHistoryState(params);
    }
}
