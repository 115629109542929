import * as Store from "../../store.js";
import * as Repository from "../../../components/repositories/news-item-repository.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { handleResponse } from "../helpers/request-helper.js";

export function loadLayout(params) {
    params = params || {};
    return handleResponse(Repository.getLayout(params), (response) => {
        Store.setStore("data", prepareItem(response.data));
        if (response.data && response.data.caption) {
            document.title = response.data.caption;
            document
                .querySelector('meta[name="og:title"]')
                .setAttribute("content", response.data.caption);
        }
        return true;
    });
}

/* HELPERS */

function prepareItem(item) {
    item.files = prepareFiles(item.files);
    if (item.cms && item.cms.actions) {
        item.cms.actions = prepareCmsActions(item.cms.actions);
    }
    return item;
}

function prepareFiles(files) {
    if (!files) {
        return files;
    }

    const downloadUrl = Store.getStore("config.fileDownloadUrl");
    const showUrl = Store.getStore("config.fileShowUrl");

    files.forEach((file, i) => {
        files[i].downloadUrl = downloadUrl.replace("{id}", file.id);
        if (file.showable) {
            files[i].showUrl = showUrl.replace("{id}", file.id);
        }
    });

    return files;
}

function prepareCmsActions(actions) {
    const editor = Store.getStore("config.editor");
    if (!editor) {
        return actions;
    }

    actions.forEach((action, index) => {
        actions[index].url += "&editor=" + editor;
    });

    return actions;
}
