// https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key
export function checkNested(obj, level, ...rest) {
  if (obj === undefined || obj === null) return false;
  if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
  return checkNested(obj[level], ...rest);
}

export function getNestedValue(obj, level, ...rest) {
  if (!obj) return false;
  if (rest.length == 0 && obj.hasOwnProperty(level)) return obj[level];
  return getNestedValue(obj[level], ...rest);
}

// копия объекта, состоящего только из данных
// то есть в нем нет функций, комплексных типов и прочего
export function copyDataObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}
