import ListItem from "./list-item.jsx";
import ActionInlinePanel from "../../../../base/components/cms/action-inline-panel.jsx";

export default class NewsListLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let list = [];

        if (this.props.list) {
            this.props.list.map(function (item, key) {
                list.push(<ListItem key={key} {...item} />);
            });
        }

        return (
            <div className="ns-news-list-layout">
                <div className="news center">
                    <div className="content">
                        <div className="container-wrapper">
                            <h2 className="header">Новости региона</h2>
                            <ActionInlinePanel {...(this.props.cms || {})} />
                            <div className="news-block">
                                <div className="news-list">{list}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
