import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as AuthHandlers from "../handlers/auth-handlers.js";

export function handleErrorsPopupCloseButtonClick(e) {
    return LayoutHandlers.closeErrorsPopup();
}

export function handleMessagesPopupCloseButtonClick(e) {
    return LayoutHandlers.closeMessagesPopup();
}

export function loadHomeLayout() {
    return LayoutHandlers.loadHomeLayout().then(() => {
        LayoutHandlers.setLayoutHistoryState();
    });
}

export function handlePageHeaderMenuClick(e, item) {
    return LayoutHandlers.loadLayout(item.name);
}

export function handlePageHeaderUserbarMenuClick(e, item) {
    switch (item.name) {
        case "logout":
            return AuthHandlers.logout();
    }
}

export function handleBreadcrumbsItemClick(e, item) {
    return LayoutHandlers.loadLayout(item.layout, item.params);
}

export function handlePageHeaderLogoClick(e) {
    return loadHomeLayout();
}
