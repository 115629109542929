import ListItem from "./list-item.jsx";
import ActionInlinePanel from "../../cms/action-inline-panel.jsx";

export default class NewsListLayout extends React.Component {
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
    }

    componentDidMount() {
        if (this.listRef && this.listRef.current) {
            if (
                typeof PostloadPlaceholders !== "undefined" &&
                PostloadPlaceholders
            ) {
                PostloadPlaceholders.collectAndLoad(
                    $(this.listRef.current),
                    () => {}
                );
            }
        }
    }

    render() {
        let list = [];

        if (this.props.list) {
            this.props.list.map(function (item, key) {
                list.push(<ListItem key={key} {...item} />);
            });
        }

        return (
            <div className="ns-pubs-list" ref={this.listRef}>
                <ActionInlinePanel {...(this.props.cms || {})} />
                <div className="publist">{list}</div>
                {this.renderLoadMoreButton()}
            </div>
        );
    }

    renderLoadMoreButton() {
        const { totalCount, list, loadMore } = this.props;
        if (!list || !list.length || !totalCount || !loadMore) {
            return null;
        }

        if (totalCount <= list.length) {
            return null;
        }

        return (
            <button
                className="show-more-updates"
                type="button"
                data-url={loadMore.url}
                onClick={this.handleLoadMoreButtonClick.bind(this, loadMore)}
            >
                <span>{loadMore.label}</span>
            </button>
        );
    }

    handleLoadMoreButtonClick(button, e) {
        const handler = this.props.handleLoadMoreButtonClick || function () {};
        handler(button);
    }
}
