import { decode } from "../../../../base/helpers/html-helper.js";
import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class NewsItemLayout extends React.Component {
    constructor(props) {
        super(props);
        this.itemRef = React.createRef();
    }

    componentDidMount() {
        if (this.itemRef && this.itemRef.current) {
            if (
                typeof PostloadPlaceholders !== "undefined" &&
                PostloadPlaceholders
            ) {
                PostloadPlaceholders.collectAndLoad(
                    $(this.itemRef.current),
                    () => {}
                );
            }
        }
    }

    render() {
        const item = this.props;
        if (!item) {
            return null;
        }

        let dates = [];
        if (item.dates) {
            item.dates.map((date, i) => {
                dates.push(<p key={i}>{date}</p>);
            });
        }

        let protectedBlock;
        if (
            this.props.options &&
            this.props.options.protect &&
            this.props.options.protect.enable_protection
        ) {
            const info = this.props.options.protect;
            protectedBlock = (
                <div className="copyright-string">
                    <p>
                        <a href={info.link} target="_blank">
                            Copyright © {info.date_copyrighted} {info.author}
                        </a>
                    </p>
                </div>
            );
        }

        let visits = (
            <div className="model-item-placeholder"
                data-type="visits"
                data-model-name="news-service-item"
                data-model-id={item.id}
                data-params="null"
            >
                <div className="view-counts">
                    <p>
                        Просмотров всего:  <span>{item.visits_total}</span>, сегодня: <span>{item.visits_total}</span>
                    </p>
                </div>
        </div>
        );

        let ratings = (
            <div className="model-item-placeholder"
                data-type="ratings"
                data-model-name="news-service-item"
                data-model-id={item.id}
                data-params="null"
            >
                <span className="rates-container">
                    <span
                        className="rates vote-enabled"
                        data-url="/rating/register-and-ajax-respond"
                        data-model="news-service-item"
                        data-id={item.id}>
                        <span 
                        className="rate-stars">
                            <span className="result-layer"></span>
                            <span className="visitor"></span>
                        </span>
                    </span>
                    <span className="meta">
                        <span className="rate-value">
                            Рейтинг:
                            <span
                                className="val"
                                data-value="rating"
                            ></span>
                            .
                        </span>
                        <span> </span>
                        <span className="rate-count">
                            Оценили:
                            <span
                                className="val"
                                data-value="appraisal"
                            ></span>
                            .
                        </span>
                    </span>
                </span>
            </div>
        );

        return (
            <div className="pubitem" ref={this.itemRef}>
                <div className="page-caption">
                    <h1>{item.caption}</h1>
                    <ActionPanel {...this.props.cms} />
                </div>
                <div>
                    <div className="date">
                        <p>{item.date}</p>
                    </div>
                </div>
                <div
                    className="body"
                    dangerouslySetInnerHTML={{ __html: decode(item.body) }}
                />
                {visits}
                {ratings}
                {protectedBlock}
            </div>
        );
    }
}
