let config = {
    defaultIdParam: "id",
    layoutIdParams: {},
    rules: [],
};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function getUrlLayout(url) {
    const handler = new URL(url);
    const path = handler.pathname;

    for (let rule of config.rules) {
        let result;
        if ((result = matchPathRule(path, rule))) {
            return {
                name: rule.layout,
                params: result.params,
            };
        }
    }

    let id = handler.searchParams.get("id");
    const parts = getPathParts(path);
    const layout = parts[0];
    let params = {};

    if (parseInt(parts[1])) {
        const idParam = getLayoutIdParam(layout);
        params[idParam] = parts[1];
    }

    return {
        name: layout,
        params: params,
    };
}

export function getLayoutIdParam(name) {
    return config.layoutIdParams[name] || config.defaultIdParam;
}

/* HELPERS */

function getPathParts(path) {
    return path.replace(/^\/+|\/+$/g, "").split("/");
}

function matchPathRule(path, rule) {
    const result = path.match(rule.pattern);
    if (!result) {
        return null;
    }

    return {
        params: result.groups,
    };
}
