import * as Store from "../../store.js";
import * as Repository from "../../../components/repositories/auth-repository.js";
import * as Auth from "../../../components/auth.js";
import * as LayoutHandlers from "./layout-handlers.js";
import { getNestedValue } from "../../../components/helpers/objects-helper.js";

export function loadLayout() {
    return Repository.getLayout()
        .then((response) => {
            Store.setStore("data", response);
            Store.setStore("loading.show", false);
        })
        .catch((error) => {
            LayoutHandlers.loadErrorLayout(error);
            Store.setStore("loading.show", false);
        });
}

export function authorizeForm(form) {
    if (!form.validateForm()) {
        return null;
    }

    Store.setStore("loading.show", true);
    Store.setStore("loading.message", Store.localeString("loadingAuth"));

    return Auth.authorize(form.getFormData())
        .then((response) => {
            // видимо авторизовались
            Store.setStore(
                "loading.message",
                Store.localeString("loadingDefault")
            );
            // перейдем на домашнюю страницу
            return LayoutHandlers.loadHomeLayout();
        })
        .catch((error) => {
            Store.setStore(
                "loading.message",
                Store.localeString("loadingDefault")
            );
            let data = getNestedValue(error, "response");
            if (data && data.errors) {
                Store.setStore("data.authForm.errors", data.errors);
            } else {
                LayoutHandlers.loadErrorLayout();
            }
            Store.setStore("loading.show", false);
        });
}

export function logout() {
    Store.setStore("data.loading.show", true);
    Store.setStore("data.loading.message", Store.localeString("loadingLogout"));

    Auth.logout().then(() => {
        Store.setStore("loading.message", Store.localeString("loadingDefault"));
        return LayoutHandlers.loadHomeLayout();
    });
}
