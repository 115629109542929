import { decode } from "../../../../base/helpers/html-helper.js";
import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class NewsItemLayout extends React.Component {
    constructor(props) {
        super(props);
        this.itemRef = React.createRef();
    }

    componentDidMount() {
        if (this.itemRef && this.itemRef.current) {
            if (
                typeof PostloadPlaceholders !== "undefined" &&
                PostloadPlaceholders
            ) {
                PostloadPlaceholders.collectAndLoad(
                    $(this.itemRef.current),
                    () => {}
                );
            }
        }
    }

    render() {
        const item = this.props;
        if (!item) {
            return null;
        }

        let dates = [];
        if (item.dates) {
            item.dates.map((date, i) => {
                dates.push(<p key={i}>{date}</p>);
            });
        }

        let defaultImage;
        let images = [];
        if (item.images) {
            const galleryId = "image-gallery-" + item.id;
            let first = true;
            item.images.map((image, i) => {
                if (first) {
                    defaultImage = (
                        <div className="default-image">
                            <a
                                href={image.urls.big}
                                className="popup-image"
                                rel={galleryId}
                            >
                                <img src={image.urls.big} alt="pic" />
                            </a>
                        </div>
                    );
                    first = false;
                } else {
                    images.push(
                        <li className="picture" key={i}>
                            <a
                                href={image.urls.big}
                                className="popup-image"
                                rel={galleryId}
                            >
                                <img src={image.urls.thumb} alt="pic" />
                            </a>
                        </li>
                    );
                }
            });

            images = <ul className="images-gallery">{images}</ul>;
        }

        let files = [],
            links = [];
        if (item.files) {
            item.files.map((file, i) => {
                links = [];
                if (file.showUrl) {
                    links.push(
                        <a
                            href={file.showUrl}
                            className="show"
                            target="_blank"
                            key="show-url"
                        >
                            (Посмотреть)
                        </a>
                    );
                }
                if (file.downloadUrl) {
                    links.push(
                        <a
                            href={file.downloadUrl}
                            className="download"
                            target="_blank"
                            key="download-url"
                        >
                            (Скачать)
                        </a>
                    );
                }
                if (!links.length && file.url) {
                    links.push(
                        <a
                            href={file.url}
                            className="download"
                            target="_blank"
                            key="url"
                        >
                            (Скачать)
                        </a>
                    );
                }
                files.push(
                    <div className="files-item" key={i}>
                        <span className={"icon " + file.ext}></span>
                        <span className="caption">{file.caption}</span>
                        {links}
                    </div>
                );
            });

            files = <div className="files-list">{files}</div>;
        }

        let protectedBlock;
        if (
            this.props.options &&
            this.props.options.protect &&
            this.props.options.protect.enable_protection
        ) {
            const info = this.props.options.protect;
            protectedBlock = (
                <div className="copyright-string">
                    <p>
                        <a href={info.link} target="_blank">
                            Copyright © {info.date_copyrighted} {info.author}
                        </a>
                    </p>
                </div>
            );
        }

        return (
            <div className="pubitem" ref={this.itemRef}>
                <div className="page-caption">
                    <h1>{item.caption}</h1>
                    <ActionPanel {...this.props.cms} />
                </div>
                <div className="meta">
                    <span className="date">{item.date}</span>
                    <span className="delim"></span>
                    <span className="item-views">
                        <span className="icon"></span>
                        <span className="value">{item.visits_today}</span>
                    </span>
                    <span className="delim"></span>
                    <span
                        className="model-item-placeholder item-ratings"
                        data-type="ratings"
                        data-model-name="news-service-item"
                        data-model-id={item.id}
                        data-params="null"
                    >
                        <span
                            className="rates vote-enabled"
                            data-url="/rating/register-and-ajax-respond"
                            data-model="news-service-item"
                            data-id={item.id}
                        >
                            <span className="rate-stars">
                                <span className="result-layer"></span>
                                <span className="visitor"></span>
                            </span>
                        </span>
                    </span>
                </div>
                {defaultImage}
                <div
                    className="body"
                    dangerouslySetInnerHTML={{ __html: decode(item.body) }}
                />
                {images}
                {files}
                <div className="dates-list">{dates}</div>
                {protectedBlock}
            </div>
        );
    }
}
