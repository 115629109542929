import ActionPanel from "../../cms/action-panel.jsx";

export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let img;
        if (this.props.image) {
            img = (
                <div className="pict">
                    <img src={this.props.image} alt="" />
                </div>
            );
        }

        let viewCounts;
        if (this.props.visits_total) {
            viewCounts = (
                <div className="view-counts">
                    <p>
                        Просмотров всего: <span>{this.props.visits_total}</span>
                        , сегодня <span>{this.props.visits_today}</span>
                    </p>
                </div>
            );
        }

        return (
            <div className="list-item">
                <div className="date">
                    <p>{this.props.date}</p>
                </div>
                {img}
                <div className="caption">
                    <a href={this.props.url}>{this.props.caption}</a>
                    <ActionPanel {...this.props.cms} />
                </div>
                <div className="annotation">
                    <p>{this.props.annotation}</p>
                </div>
                {viewCounts}
                <div
                    className="model-item-placeholder"
                    data-type="ratings"
                    data-model-name="news-service-item"
                    data-model-id={this.props.id}
                    data-params="null"
                >
                    <span className="rates-container">
                        <span className="rates">
                            <span className="rate-stars">
                                <span className="result-layer"></span>
                                <span className="visitor"></span>
                            </span>
                        </span>
                    </span>
                </div>
                <div className="clear"></div>
            </div>
        );
    }
}
