import ListItem from "./list-item.jsx";
import ActionInlinePanel from "../../cms/action-inline-panel.jsx";

export default class NewsSideListLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let groups = [],
            list = [],
            counter = 0,
            groupLimit = 2,
            groupId = 1;

        if (!this.props.list && !this.props.cms) {
            return null;
        }

        if (this.props.list) {
            this.props.list.map(function (item, key) {
                counter++;

                list.push(<ListItem key={key} {...item} />);

                if (counter >= groupLimit) {
                    counter = 0;
                    groups.push(
                        <div
                            className="notices-group list-item"
                            data-id={groupId}
                            key={groupId}
                        >
                            {list}
                        </div>
                    );
                    list = [];
                    groupId++;
                }
            });

            if (list.length) {
                // остатки
                groups.push(
                    <div
                        className="notices-group list-item"
                        data-id={groupId}
                        key={groupId}
                    >
                        {list}
                    </div>
                );
            }
        }

        return (
            <div className="side-box section-limiter">
                <div className="out">
                    <div className="in">
                        <div className="header">
                            <p>Новости региона</p>
                        </div>
                        <ActionInlinePanel {...(this.props.cms || {})} />
                        <div className="notices-container scroll-container">
                            <div className="scroll-content pubs-list">
                                {groups}
                            </div>
                        </div>
                        <div className="gallery-pager"></div>
                    </div>
                </div>
            </div>
        );
    }
}
