import ListItem from "./list-item.jsx";
import ActionInlinePanel from "../../../../base/components/cms/action-inline-panel.jsx";

export default class NewsListLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let list = [];
        if (this.props.list) {

            this.props.list.map(function (item) {
                list.push(<ListItem key={item.id} {...item} />);
            });
        }

        return (
            <div className="pubs-container publist newonsite">
                <ActionInlinePanel {...(this.props.cms || {})} />
                <div className="pubs-gallery viewmode-gallery-big-first">
                    {list}
                </div>
                {this.renderLoadMoreButton()}
            </div>
        );
    }

    renderLoadMoreButton() {
        const { totalCount, list, loadMore } = this.props;
        if (!list || !list.length || !totalCount || !loadMore) {
            return null;
        }

        if (totalCount <= list.length) {
            return null;
        }

        return (
            <div className="page-links">
                <a
                    className="show-more-updates show"
                    href={loadMore.url}
                    onClick={this.handleLoadMoreButtonClick.bind(
                        this,
                        loadMore
                    )}
                >
                    <span>{loadMore.label}</span>
                </a>
            </div>
        );
    }

    handleLoadMoreButtonClick(button, e) {
        const handler = this.props.handleLoadMoreButtonClick || function () {};
        handler(button);
    }
}
