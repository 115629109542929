import newId from "../components/helpers/new-id.js";

let store = {
        data: {},
        loading: {
            show: true,
            message: "Идет загрузка",
            note: "Это может занять некоторое время",
        },
        locale: {
            dict: {
                loadingDefault: "Идет загрузка",
                loadingAuth: "Авторизация",
                loadingLogout: "Выходим",
            },
        },
        config: {
            theme: {
                name: "base",
            },
            newsItemBaseUrl: "/news-svc/item",
            fileDownloadUrl:
                "https://news-service.uralschool.aiwoo.info/v1/files/download?id={id}",
            fileShowUrl:
                "https://news-service.uralschool.aiwoo.info/v1/files/show?id={id}",
            historyState: false,
        },
    },
    handlers = {
        onUpdate: {},
    };

export let config = {
    debug: false,
};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }

    if (config.debug) {
        /* доступ к стору снаружи всех измерений */
        window.appGetStore = () => {
            return store;
        };
        window.appSetStore = (path, value) => {
            setStore(path, value);
        };
    }
}

export function getStore(path) {
    if (!path) {
        return store;
    }

    const levels = path.split(".");
    let storeLevel = store;
    for (let i = 0; i < levels.length; i++) {
        if (!storeLevel) {
            return null;
        }
        storeLevel = storeLevel[levels[i]];
    }

    return storeLevel;
}

export function setStore(path, value) {
    if (path) {
        setStorePath(path, value);
    } else {
        const storeConfig = store.config;
        store = value;
        if (!store.config) {
            store.config = storeConfig;
        }
    }
    fireHandlers("onUpdate", { path: path });
}

export function addList(path, list) {
    addListPath(list, path);
    fireHandlers("onUpdate", { path: path });
}

export function onUpdate(handler) {
    const handlerId = newId("store-handler-");
    handlers.onUpdate[handlerId] = handler;
    return handlerId;
}

export function offUpdate(handlerId) {
    handlers.onUpdate[handlerId] = null;
}

export function localeString(name) {
    return store.locale.dict[name] || name;
}

export function triggerUpdate(path) {
    fireHandlers("onUpdate", { path: path });
}

function fireHandlers(name, params) {
    if (config.debug) {
        console.log("STORE:", params.path || "full store", "updated");
    }

    for (let id in handlers[name]) {
        let handler = handlers[name][id];
        if (!handler) {
            // его может небыть после offUpdate
            continue;
        }
        handlers[name][id](params);
    }
}

//https://stackoverflow.com/questions/18936915/dynamically-set-property-of-nested-object
function setStorePath(path, value) {
    let schema = store; // a moving reference to internal objects within obj
    const pList = path.split("."),
        len = pList.length;
    for (let i = 0; i < len - 1; i++) {
        const elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len - 1]] = value;
}

function addListPath(list, path) {
    let schema = store; // a moving reference to internal objects within obj
    const pList = path.split("."),
        len = pList.length;
    for (let i = 0; i < len - 1; i++) {
        const elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[pList[len - 1]] = schema[pList[len - 1]].concat(list);
}
