import BaseNewsListLayout from "../../../../base/components/layouts/news-list/index.jsx";
import V2NewsListLayout from "../../../../v2/components/layouts/news-list/index.jsx";
import V3NewsListLayout from "../../../../v3/components/layouts/news-list/index.jsx";
import V4NewsListLayout from "../../../../v4/components/layouts/news-list/index.jsx";
import V5NewsListLayout from "../../../../v5/components/layouts/news-list/index.jsx";
import V6NewsListLayout from "../../../../v6/components/layouts/news-list/index.jsx";
import Gov2NewsListLayout from "../../../../gov2/components/layouts/news-list/index.jsx";
import KioskNewsListLayout from "../../../../kiosk/components/layouts/news-list/index.jsx";
import UoBaseNewsListLayout from "../../../../uo-base/components/layouts/news-list/index.jsx";

import ThemeContainer from "../../../theme-container.jsx";

export default class NewsListLayoutThemeContainer extends ThemeContainer {
    constructor(props) {
        super(props);
    }

    render() {
        switch (this.theme.name) {
            case "v2":
                return <V2NewsListLayout {...this.props} />;
            case "v3":
                return <V3NewsListLayout {...this.props} />;
            case "v4":
                return <V4NewsListLayout {...this.props} />;
            case "v5":
                return <V5NewsListLayout {...this.props} />;
            case "v6":
                return <V6NewsListLayout {...this.props} />;
            case "gov2":
                return <Gov2NewsListLayout {...this.props} />;
            case "kiosk":
                return <KioskNewsListLayout {...this.props} />;
            case "uo-base":
                return <UoBaseNewsListLayout {...this.props} />;
            default:
                return <BaseNewsListLayout {...this.props} />;
        }
    }
}
