export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let img = "";
        if (this.props.image) {
            img = <img src={this.props.image} />;
        }

        return (
            <div className={`pub-item item-`}>
                <a href={`${this.props.url ? this.props.url : ""}`}>
                    <span className="icon"></span>
                    <span className="img">{img}</span>
                    <span className="container">
                        <span className="caption">{this.props.caption}</span>
                        <span className="annotation">
                            {this.props.annotation}
                        </span>
                        <span className="date">{this.props.date}</span>
                    </span>
                </a>
            </div>
        );
    }
}
