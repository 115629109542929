import Application from "./base/containers/application.jsx";
import * as Store from "./store.js";
import * as Router from "./router.js";
import * as Auth from "../components/auth.js";
import * as Repository from "../components/repositories/repository.js";

export default class ApplicationLoader {
    loadApplication(config, container) {
        this.configure(config);
        ReactDOM.render(<Application />, container);
    }

    configure(config) {
        if (!config.store) {
            config.store = {};
        }
        if (config.debug) {
            config.store.debug = config.debug;
        }

        // мерж с дефолтным конфигом
        config = {
            ...(Store.getStore("config") || {}),
            ...config,
        };

        Auth.setConfig(config.auth);
        Repository.setConfig(config.repository);
        Store.setConfig(config.store);
        Store.setStore("config", config);
        Router.setConfig(config.router);
    }
}
