export default class LoadingPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="ns-loading-popup show">
                <div className="ns-loading-plate">
                    <div className="ns-loading-icon" />
                    <div className="ns-loading-message">
                        <p>{this.props.message}</p>
                    </div>
                </div>
            </div>
        );
    }
}
