export default class Paginator extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const links = this.props.links;
        if (!links || !links.length) {
            return null;
        }

        this.interval = this.props.interval || 7;
        this.pagesFrom = 0;
        this.pagesTo = links.length;

        this.fixIntervalValues();

        let pages = [];
        for (let page = this.pagesFrom; page < this.pagesTo; page++) {
            pages.push();
        }

        let firstPage, firstPageDelim, lastPage, lastPageDelim;

        if (this.pagesFrom > 0) {
            firstPage = this.renderPage(links[0], "first-page");
        }

        if (this.pagesFrom > 1) {
            firstPageDelim = (
                <li className="pager-item delim" key="first-page-delim">
                    <span> ... </span>
                </li>
            );
        }

        if (this.pagesTo < links.length) {
            lastPage = this.renderPage(links[links.length - 1], "last-page");
        }

        if (this.pagesTo < links.length - 1) {
            lastPageDelim = (
                <li className="pager-item delim" key="first-page-delim">
                    <span> ... </span>
                </li>
            );
        }

        return (
            <div className="pager-container">
                <ul>
                    {firstPage}
                    {firstPageDelim}
                    {links.map((item, idx) => {
                        if (idx < this.pagesFrom || idx > this.pagesTo) {
                            return null;
                        }
                        return this.renderPage(item, idx);
                    })}
                    {lastPageDelim}
                    {lastPage}
                </ul>
            </div>
        );
    }

    renderPage(item, idx) {
        return (
            <li
                className={`pager-item ${
                    item.page == this.props.active ? "active" : ""
                }`}
                onClick={() => {
                    this.handlePagerLinkClick(item);
                }}
                key={idx}
            >
                <button className="pager-btn">
                    {item.caption || item.page}
                </button>
            </li>
        );
    }

    fixIntervalValues() {
        let pageIndex = this.props.active;
        if (!pageIndex) {
            return null;
        }
        pageIndex--;

        const sidePagesCount = (this.interval - 1) / 2;
        this.pagesFrom = pageIndex - sidePagesCount;
        this.pagesTo = pageIndex + sidePagesCount;
        if (this.pagesFrom < 0) {
            this.pagesTo -= this.pagesFrom;
            this.pagesFrom = 0;
        } else if (this.pagesTo > this.props.links.length) {
            this.pagesFrom -= this.pagesTo - this.props.links.length;
            this.pagesTo = this.props.links.length;
        }
    }

    handlePagerLinkClick(item) {
        const handler = this.props.onPageClick || function () {};
        handler(item);
    }
}
