import ActionPanel from "../../cms/action-panel.jsx";

export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let img;
        if (this.props.image) {
            img = <img src={this.props.image} alt="" />;
        }

        let hostCaption = this.props.host_caption;
        if (hostCaption) {
            hostCaption = <span><br/>{hostCaption}</span>
        }

        return (
            <div className="item-newsannounce item-news">
                <div className="img">{img}</div>
                <div className="item-content">
                    <div className="caption">
                        <a href={this.props.url}>{this.props.caption}</a>
                    </div>
                    <div className="note-date">
                        <span className="date">{this.props.day}</span>
                        <span className="type"></span>
                        <span className="note-add">
                            {this.props.note} {this.props.date}
                            {hostCaption}
                        </span>
                    </div>
                </div>
                <ActionPanel {...this.props.cms} />
            </div>
        );
    }
}
