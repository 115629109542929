import * as Store from "../../store.js";
import * as LayoutHandlers from "../handlers/layout-handlers.js";
import * as Repository from "../../../components/repositories/repository.js";
import { getNestedValue } from "../../../components/helpers/objects-helper.js";

export function handleResponse(request, successHandler, params) {
    params = params || {};
    showLoading(params);
    return request
        .then((response) => {
            hideLoading(params);
            if (
                response.errors &&
                getNestedValue(response.info, "errorMessage")
            ) {
                // так обычно выглядит исключение на беке
                debugMessage("exception response", response);
                LayoutHandlers.handleRequestFail();
                return response;
            }
            if (!successHandler(response)) {
                debugMessage("invalid response", response);
                LayoutHandlers.handleRequestFail();
            }
            return response;
        })
        .catch((error) => {
            hideLoading(params);
            LayoutHandlers.handleRequestFail(error);
            debugMessage(error);
        });
}

export function submitForm(form) {
    Store.setStore("loading.show", true);

    if (!form || !form.validateForm() || !form.props.action) {
        Store.setStore("loading.show", false);
        return Promise.reject();
    }

    return handleResponse(
        Repository.request("POST", form.props.action, form.getFormData()),
        (response) => {
            if (!response) {
                return false;
            }

            return response;
        }
    );
}

export function request(url, data) {
    return handleResponse(Repository.request("POST", url, data), (response) => {
        if (!response) {
            return false;
        }

        return response;
    });
}

/* HELPERS */

function debugMessage(message, data) {
    if (Store.config.debug) {
        console.log(message, data);
    }
}

function showLoading(params) {
    if (params.loading === false) {
        return;
    }

    Store.setStore("loading.show", true);
}

function hideLoading(params) {
    if (params.loading === false) {
        return;
    }

    Store.setStore("loading.show", false);
}
