import ActionPanel from "../../../../base/components/cms/action-panel.jsx";

export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let hostCaption = this.props.host_caption;
        if (hostCaption) {
            hostCaption = <p className="date">{hostCaption}</p>;
        }

        return (
            <div className="list-item">
                <div className="image">
                    <img src={this.props.image} alt="" />
                </div>
                <div className="caption">
                    <a href={this.props.url}>{this.props.caption}</a>
                    <ActionPanel {...this.props.cms} />
                </div>
                <div className="read-more">
                    <a href={this.props.url}>
                        <span className="text">Читать далее</span>
                        <span className="icon"></span>
                    </a>
                </div>
                <div className="meta">
                    <span className="date">{this.props.date}</span>
                    <div className="counter"></div>
                    {hostCaption}
                </div>
            </div>
        );
    }
}
