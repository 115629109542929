import * as RequestHelper from '../request-helper.js';

export class OAuth {
    constructor(config) {
        this.authPageUrl = config.authPageUrl;
        this.tokenUrl = config.tokenUrl;
        this.authRedirectUrl = config.authRedirectUrl;
        this.clientId = config.clientId;
        this.clientSecret = config.clientSecret;
        this.nocors = config.nocors;

        this.debugMessages = [];

        this.stateStorageName = config.stateStorageName || '0sc69877d78c4a253c57490617efa8e2';
    }

    getToken(params) {
        params = params || {};
        if (params.authorizationCode) {
            this.debugMessage('request access token');
            if (!this.verifyState(params.state)) {
                this.debugMessage('wrong state');
                return Promise.reject();
            }
            return this.requestToken(params);
        } else {
            this.debugMessage('redirect to auth page');
            this.redirectToAuthPage(params);
        }
    }

    refreshToken(refreshToken) {
        return this.requestRefreshToken(refreshToken);
    }

    /* HELPERS */

    redirectToAuthPage(params) {
        let url = RequestHelper.addUrlParams(
            this.authPageUrl,
            [
                ['client_id', this.clientId],
                ['response_type', 'code'],
                ['redirect_uri', encodeURIComponent(this.authRedirectUrl)],
                ['state', this.generateNewState()]
            ]
        );
        if (params.scope) {
            url = RequestHelper.addUrlParam(url, 'scope', params.scope);
        }
        this.debugMessage('redirecting to ' + url);
        location.href = url;
    }

    requestToken(params) {
        const url = this.tokenUrl;
        let body = {
            client_id: this.clientId,
            code: params.authorizationCode,
            grant_type: 'authorization_code',
            redirect_uri: this.authRedirectUrl
        }

        if (this.clientSecret) {
            body.client_secret = this.clientSecret;
        }

        return this.request('POST', url, body, {
            nocors: this.nocors
        });
    }

    requestRefreshToken(refreshToken) {
        const url = this.tokenUrl;
        let body = {
            client_id: this.clientId,
            refresh_token: refreshToken,
            grant_type: 'refresh_token'
        }

        if (this.clientSecret) {
            body.client_secret = this.clientSecret;
        }

        return this.request('POST', url, body, {
            nocors: this.nocors
        });
    }

    debugMessage(message) {
        this.debugMessages.push(message);
    }

    getDebugMessages() {
        return this.debugMessages;
    }

    request(method, url, body, options) {
        this.debugMessage('prepare request');

        var fetchOptions = {
            method: method
        };

        if (body) {
            fetchOptions.body = RequestHelper.ensureFormData(body);
            // body = RequestHelper.ensureQueryString(body);
            // fetchOptions.body = JSON.stringify(body);
            // fetchOptions.headers = {
            //     'Content-Type': 'application/json;charset=UTF-8'
            // };
        }

        if (options.nocors) {
            fetchOptions.mode = 'no-cors';
        }

        return fetch(url, fetchOptions)
            .then(response => {
                this.debugMessage('response status: ' + response.status);
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                throw new OAuthError('Request fail', response);
            });
    }

    generateNewState() {
        // https://gist.github.com/6174/6062387
        let state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        sessionStorage.setItem(this.stateStorageName, state);
        return state;
    }

    verifyState(state) {
        const storedState = sessionStorage.getItem(this.stateStorageName);
        sessionStorage.removeItem(this.stateStorageName);
        this.debugMessage('verifying state ' + JSON.stringify({state, storedState}));
        return state == storedState;
    }
}

function OAuthError(message, response) {
  this.name = "OAuthError";
  this.message = (message || "");
  this.response = response;
}
OAuthError.prototype = Object.create(Error.prototype);
