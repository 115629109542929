import * as Store from "../../store.js";

export default class Container extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        this.disconnectStore(this.storePath, this.storeHandlerId);
    }

    connectStore(storePath) {
        this.debugMessage("CONTAINER: connect ", storePath);
        this.storePath = storePath;
        this.state = {
            // подгорел пока нагуглил, что невозможно сделать пустой стейт
            state: Store.getStore(storePath),
        };
        this.storeHandlerId = Store.onUpdate((params) => {
            if (
                !params.path ||
                (params.path && params.path.indexOf(storePath) > -1)
            ) {
                this.debugMessage("CONTAINER: update state for " + storePath);
                this.setState({
                    state: Store.getStore(storePath),
                });
            }
        });
    }

    disconnectStore(storePath, handlerId) {
        if (!handlerId) {
            return null;
        }

        this.debugMessage("CONTAINER: disconnect ", storePath);
        Store.offUpdate(handlerId);
    }

    debugMessage(message, data) {
        if (Store.config.debug) {
            console.log(message, data);
        }
    }
}
