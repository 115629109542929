export default class ActionInlinePanel extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.actions || !this.props.actions.length) {
            return null;
        }

        return (
            <div className="cms-action-inline-panel">
                <nav className="cms-actions">
                    {this.props.actions.map((action, index) => {
                        return (
                            <button
                                className="cms-action add cms-action-inline-button"
                                data-url={action.url}
                                key={index}
                            >
                                <span className="cms-action-icon"></span>
                                {action.title}
                            </button>
                        );
                    })}
                </nav>
            </div>
        );
    }
}
