import ActionPanel from "../../cms/action-panel.jsx";

export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let hostCaption = this.props.host_caption;
        if (hostCaption) {
            hostCaption = <p>{hostCaption}</p>
        }

        return (
            <div className="notice">
                <p className="date">{this.props.date}</p>
                <ActionPanel {...this.props.cms} />
                {hostCaption}
                <a className="caption" href={this.props.url}>{this.props.caption}</a>
            </div>
        );
    }
}
