import * as Store from "../../store.js";
import * as Router from "../../router.js";
import * as Auth from "../../../components/auth.js";
import * as LayoutHandlers from "./layout-handlers.js";

export function loadUrlLayout(url) {
    return parseUrlLayout(url);
}

/* HELPERS */

function parseUrlLayout(url) {
    const layout = Router.getUrlLayout(url);

    if (layout && layout.name) {
        return LayoutHandlers.loadLayout(layout.name, layout.params);
    }

    return LayoutHandlers.loadHomeLayout();
}

function debugMessage(message, data) {
    if (Store.config.debug) {
        console.log(message, data);
    }
}
