export default class ActionPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.actions || !this.props.actions.length) {
            return null;
        }

        return (
            <div className="cms-action-panel sh-block sh-hidden">
                <nav className="cms-actions">
                    {this.props.actions.map((action, index) => {
                        return (
                            <button
                                className="cms-action"
                                data-url={action.url}
                                key={index}
                            >
                                {action.title}
                            </button>
                        );
                    })}
                </nav>
                <button className="sh-btn panel-btn cms-action-icon edit"></button>
            </div>
        );
    }
}
